
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "invite-friends-modal",
  data() {
    return {
      cliente:{
        nome:''
      },
      clientes:[],
      selecionado: {},
    }
  },

  watch:{
     'cliente.nome': function (val, oldVal) {
       this.pesquisarCliente(val);
    },
  },
  methods:{

    
    setarSelecionado(endereco_id){

      this.cliente.nome = ''
      this.$emit('selecionado', endereco_id)

    },

    pesquisarCliente(termo) {
      axios.get(process.env.VUE_APP_API_URL + '/pesquisar?busca='+termo)
       .then(({ data }) => {   
        this.clientes = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });
    },

  },
  components: {},
  setup() {
    

    return {
      
    };
  },
});
